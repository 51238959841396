/* .max-44 .Polaris-Layout__Section {
    max-width: calc(50% - 2rem);
    margin-right: auto;
} */

.Polaris-Layout {
    margin-bottom: 4rem;
}

/* @media screen and (max-width: 768px){
    .max-44 .Polaris-Layout__Section {
        max-width: calc(100% - 2rem);
    }
} */